export class Sorter {
  private els: NodeListOf<HTMLElement>;
  private elsArr: HTMLElement[];
  private container: HTMLElement;
  private buttonSortASC: HTMLElement;
  private buttonSortDESC: HTMLElement;

  constructor(container: string) {
    this.container = document.querySelector(container);
    this.els = document.querySelectorAll('[data-years]');

    if (this.els && this.container) {
      this.buttonSortASC = document.querySelector('.js-sort-asc');
      this.buttonSortDESC = document.querySelector('.js-sort-deasc');

      if (this.buttonSortASC && this.buttonSortDESC) {
        this.events();
      }
    }
  }

  events(): void {
    this.buttonSortASC.addEventListener('click', this.sortArrASC.bind(this));
    this.buttonSortDESC.addEventListener('click', this.sortArrDESC.bind(this));
  }

  sortArrASC(e: Event): void {
    e.preventDefault();
    const elsArr = this.convertToArray();
    elsArr.sort((a, b) => parseInt(b.dataset.years, 10) - parseInt(a.dataset.years, 10));
    this.appendNodes(elsArr);
  }

  sortArrDESC(e: Event): void {
    e.preventDefault();
    const elsArr = this.convertToArray();
    elsArr.sort((a, b) => parseInt(a.dataset.years, 10) - parseInt(b.dataset.years, 10));
    this.appendNodes(elsArr);
  }

  convertToArray(): HTMLElement[] {
    return Array.from(this.els);
  }

  appendNodes(arrayOfNodes: HTMLElement[]): void {
    this.container.innerHTML = '';
    arrayOfNodes.forEach((node: HTMLElement): void => {
      this.container.appendChild(node);
    });
  }
}
