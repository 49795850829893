export class Header {
  private header: HTMLElement;

  constructor(public section: HTMLElement) {
    this.header = this.section.querySelector('.header');
  }

  private getHeight(): number {
    return this.header.offsetHeight;
  }

  private setHeight(paddingTop: number): void {
    const offset = 60;
    this.section.style.paddingTop = `${Math.ceil(paddingTop + offset)}px`;
  }

  updateHeight(): void {
    const height = this.getHeight();
    this.setHeight(height);
  }
}
