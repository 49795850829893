import "../scss/style.scss";
import { VimeoPlayer } from "./components/VimeoPlayer";
import { Filter } from "./components/Filter";
import { Login } from "./components/Login";
import { ButtonAnimation } from "./components/ButtonAnimation";
import { Sorter } from "./components/Sorter";
import { Preloader } from "./components/Preloader";

(function() {
  if (typeof (NodeList as any).prototype.forEach !== "function") {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }

  return false;
})();

const onLoad = (e: Event) => {
  inits();
  onResize(e);
};

const onResize = (e: Event) => {
  onScroll(e);
};

const onScroll = (e: Event) => {};

const inits = () => {
  const vimeoPlayer = new VimeoPlayer(".js-vimeo-player");
  const filter = new Filter(".portfolio-filter");
  const login = new Login(".page--login");
  const buttonAnimation = new ButtonAnimation(".button");
  const sorter = new Sorter(".js-sort-container");
  const preloader = new Preloader(".preloader");
};

window.addEventListener("load", onLoad);
window.addEventListener("resize", onResize);
window.addEventListener("scroll", onScroll);
