import player, { Options } from '@vimeo/player';

export class VimeoPlayer {
  private player: HTMLElement;
  private video: player;

  constructor(public playerSelector: string) {
    this.player = document.querySelector(playerSelector);
    if (this.player) {
      this.init();
    }
  }

  init(): void {
    const playBtn: HTMLElement = this.player.querySelector('.js-play-video');
    if (playBtn) playBtn.addEventListener('click', this.playVideo.bind(this));
    this.loadPlayer();
  }

  loadPlayer(): void {
    const options: Options = {
      responsive: true,
    };
    this.video = new player(this.player, options);
    this.video.on('play', this.toggleOnPlay.bind(this));
    this.video.on('stop', this.toggleOnPlay.bind(this));
  }

  playVideo(): void {
    this.video.play();
  }

  toggleOnPlay(): void {
    this.player.classList.toggle('isPlaying');
  }
}
