export class ButtonAnimation {
  private buttons: NodeListOf<HTMLElement>;
  private state: {} = {};
  private readyForTransition: boolean = true;

  constructor(selector: string) {
    this.buttons = document.querySelectorAll(selector);
    if (this.buttons) this.init();
  }

  init(): void {
    this.buttons.forEach((button: HTMLElement): void => {
      this.replaceText(button);
      button.addEventListener('mouseenter', this.onHover.bind(this));
      // button.addEventListener('transitionend', this.transitionend.bind(this));
      button.addEventListener('mouseleave', this.onLeave.bind(this));
    });
  }

  onHover(e: Event): void {
    if (this.readyForTransition) {
      const el = this.targetElement(e);
      el.dataset.state = 'hover';
      // this.readyForTransition = false;
    }
  }

  onLeave(e: Event): void {
    if (this.readyForTransition) {
      const el = this.targetElement(e);
      el.dataset.state = 'leave';
      setTimeout(() => {
        el.dataset.state = '';
      }, 600);
    } else {
    }
  }

  targetElement(event: Event): HTMLElement {
    return event.target as HTMLElement;
  }

  replaceText(button: HTMLElement): void {
    let delay = 95;
    const text: string = button.innerText;
    const textArr: string[] = text.split('');
    const newTextArr: string[] = textArr.map(
      (letter: string): string => `<span style="transition-delay: ${(delay += 20)}ms;">${letter}</span>`
    );
    button.innerHTML = `<span class="span span--1" >${newTextArr.join('')}</span><span class="span span--2" >${newTextArr.join('')}</span>`;
  }

  transitionend(): void {
    console.log(this.readyForTransition);
    this.readyForTransition = true;
  }
}
