import axios from 'axios';
import * as qs from 'qs';

export class Login {
  constructor(selector: string) {
    const element: HTMLElement = document.querySelector(selector);
    if (element) {
      this.initLoginForm(element);
    }
  }

  initLoginForm(element: HTMLElement): void {
    const form: HTMLFormElement = element.querySelector('.login-form');
    const passwordInput: HTMLFormElement = form.querySelector('.form__input--password');

    if (passwordInput && form) {
      form.addEventListener('submit', (event: Event) => {
        const valid = this.validateForm(passwordInput);
        if (valid) {
          this.submitForm(passwordInput.value, element);
        }

        event.preventDefault();
      });
    }
  }

  validateForm(passwordInput: HTMLFormElement) {
    let valid = true;
    if (passwordInput.value === '') {
      passwordInput.classList.add('form__input--error');
      valid = false;
    }

    return valid;
  }

  submitForm(password: string, element: HTMLElement) {
    const errorMessage = element.querySelector('.form__error');
    const formSubmitButton: HTMLFormElement = element.querySelector('.button--login');

    formSubmitButton.classList.add('button--disabled');
    errorMessage.classList.remove('form__error--show');

    axios({
      method: 'post',
      url: '/endpoints/login',
      data: qs.stringify({
        password: password
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response: any) => {
      formSubmitButton.classList.remove('button--disabled');

      if (response.data.status === 'success') {
        location.reload(true);
      } else {
        errorMessage.classList.add('form__error--show');
      }
    });
  }
}
