declare var TimelineMax: any, Linear: any, ScrollMagic: any;

export class BoxAnimation {
  private textBlock: HTMLElement;
  private animateFrom: string;

  constructor(public container: HTMLElement) {
    if (this.container) {
      this.textBlock = this.container.querySelector(".js-text-block");
      this.animateFrom = this.textBlock.dataset.scrollFrom;

      this.init();
    }
  }

  init(): void {
    const controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: this.container,
      triggerHook: "onLeave",
      duration: "190%"
    })
      .setPin(this.container)
      .setTween(this.setupTimelineMax())
      // .addIndicators({
      //   colorTrigger: "white",
      //   colorStart: "white",
      //   colorEnd: "white",
      //   indent: 40
      // })
      .addTo(controller);
  }

  setupTimelineMax() {
    TimelineMax.defaultEase = Linear.easeOut;
    const tl = new TimelineMax();
    if (this.animateFrom === "left") {
      tl.from(this.textBlock, 1, {
        xPercent: -100
      });
    } else {
      tl.from(this.textBlock, 1, {
        xPercent: 100
      });
    }

    return tl;
  }
}
