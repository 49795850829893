import { Header } from './Header';

export class Filter {
  private selectContainer: HTMLElement;
  private dropDownItems: NodeListOf<HTMLInputElement>;
  private filterLinks: NodeListOf<HTMLElement>;
  private labelsContainer: HTMLElement;
  private searchInput: HTMLInputElement;
  private fixedHeader: HTMLElement;
  private header: Header;

  constructor(selector: string) {
    this.selectContainer = document.querySelector(selector);
    if (this.selectContainer) {
      this.labelsContainer = this.selectContainer.querySelector('.portfolio-filter__selected');
      this.searchInput = this.selectContainer.querySelector('.portfolio-filter__search');
      this.fixedHeader = document.querySelector('section.section__portfolio--fixed-header');
      this.init();
    }
  }

  init(): void {
    this.events();

    if (this.fixedHeader) {
      this.header = new Header(this.fixedHeader);
      this.header.updateHeight();
    }
  }

  events(): void {
    document.addEventListener('click', this.documentClick.bind(this));
    this.selectContainer.querySelector('.portfolio-filter__wrap').addEventListener('click', this.dropdownOpen.bind(this));
    this.selectContainer.querySelector('.portfolio-filter__selected').addEventListener('click', this.removeFilterItem.bind(this));

    this.filterLinks = document.querySelectorAll('[data-filter-link]');
    if (this.filterLinks) {
      this.filterLinks.forEach((element: HTMLElement): void => {
        element.addEventListener('click', this.sortCategory.bind(this));
      });
    }

    this.dropDownItems = this.selectContainer.querySelectorAll('.portfolio-filter__dropdown-item input[type="checkbox"]');
    if (this.dropDownItems) {
      this.dropDownItems.forEach((element: HTMLInputElement): void => {
        element.addEventListener('change', this.observeInputChange.bind(this));
      });
    }
    if (this.searchInput) this.searchInput.addEventListener('keyup', this.search.bind(this));
  }

  sortCategory(e: Event) {
    const link: HTMLElement = e.target as HTMLElement;
    const category: string = link.dataset.filterLink;
    const item: HTMLInputElement = document.querySelector(`#${category}`) as HTMLInputElement;
    if (item) {
      if (!item.checked) {
        item.checked = true;
        item.dispatchEvent(new Event('change'));
      }
    }
  }

  search(e: Event) {
    const searchInput: HTMLInputElement = e.target as HTMLInputElement;
    const li: NodeListOf<HTMLElement> = this.selectContainer.querySelectorAll('.portfolio-filter__dropdown-li');
    const regex: RegExp = new RegExp(searchInput.value, 'gi');
    li.forEach((el: HTMLElement): void => {
      const checkbox: HTMLElement = el.querySelector('input[type="checkbox"]');
      const checkboxLabel: string = checkbox.dataset.label;
      el.classList.remove('notInSearch');
      if (!checkboxLabel.match(regex)) el.classList.add('notInSearch');
    });
  }

  removeFilterItem(e: Event) {
    const el = e.target as HTMLElement;
    if (el.matches('.portfolio-filter__label')) {
      //  update checkbox
      const checkboxID: string = el.dataset.id;
      const input: HTMLInputElement = this.selectContainer.querySelector(`#${checkboxID}`);

      if (input) {
        (input.parentNode as HTMLLabelElement).click();
        input.checked = false;
      }
      //  remove from DOM
      this.removeLabelFromWrap(el);
    }
  }

  observeInputChange(e: Event): void {
    const item: HTMLInputElement = e.target as HTMLInputElement;
    if (item.checked) {
      const labelElement = this.createLabel(item);
      this.addLabelToWrap(labelElement);
    }
    this.toggleHideDropdownItem(item);
  }

  filter(): void {
    const selectedFilters: NodeListOf<HTMLElement> = document.querySelectorAll('[data-id]');
    const projects: NodeListOf<HTMLElement> = document.querySelectorAll('.portfolio__wrapper');

    if (projects.length) {
      projects.forEach(project => {
        project.classList.add('portfolio__wrapper--hidden');
      });
    }

    if (selectedFilters.length) {
      selectedFilters.forEach(filter => {
        const filterId: string = filter.dataset.id;
        projects.forEach(project => {
          const projectFilter: string = project.dataset.filterId;
          if (filterId === projectFilter) {
            project.classList.remove('portfolio__wrapper--hidden');
          }
        });
      });
    } else {
      if (projects.length) {
        projects.forEach(project => {
          project.classList.remove('portfolio__wrapper--hidden');
        });
      }
    }
  }

  createLabel(item: HTMLInputElement): HTMLElement {
    const elem = <HTMLSpanElement>document.createElement('span');
    elem.classList.add('portfolio-filter__label');
    elem.innerText = item.dataset.label;
    elem.dataset.id = item.id;
    return elem;
  }

  addLabelToWrap(labelHTML: Element): void {
    if (this.labelsContainer) {
      setTimeout(() => {
        labelHTML.classList.add('animateShow');
      }, 0);
      this.labelsContainer.insertAdjacentElement('beforeend', labelHTML);
      this.filter();
    }

    if (this.fixedHeader) {
      this.header.updateHeight();
    }
  }

  removeLabelFromWrap(el: HTMLElement): void {
    const parent = el.parentNode;
    // el.classList.remove('animateShow');
    // setTimeout(() => {
    parent.removeChild(el);
    this.filter();
    if (this.fixedHeader) {
      this.header.updateHeight();
    }
    // }, 250);
  }

  documentClick(e: Event): void {
    const target = e.target as HTMLElement;
    if (!target.closest('.portfolio-filter') && !target.classList.contains('portfolio-filter__label')) this.dropdownClose();
  }

  dropdownOpen(): void {
    this.selectContainer.classList.add('showDropdown');
  }

  dropdownClose(): void {
    this.selectContainer.classList.remove('showDropdown');
  }

  toggleHideDropdownItem(item: HTMLElement): void {
    const listItem = item.closest('.portfolio-filter__dropdown-li');
    listItem.classList.toggle('hide');
  }
}
