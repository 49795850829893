import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { BoxAnimation } from "./BoxAnimation";

export class Preloader {
  private preloader: HTMLElement;
  private main: HTMLElement = document.querySelector("main");
  private b: HTMLElement = document.querySelector("body");
  constructor(selector: string) {
    this.preloader = document.querySelector(selector);
    if (this.preloader) {
      disableBodyScroll(this.preloader);
      this.init();
    }
  }

  init(): void {
    setTimeout(() => {
      this.b.classList.add("isLoaded");
      enableBodyScroll(this.preloader);
      this.initBoxAnimation();
    }, 3500);
  }

  initBoxAnimation(): void {
    const intViewportWidth = window.innerWidth;
    if (intViewportWidth > 1024) {
      document
        .querySelectorAll(".js-animate-box")
        .forEach((section: HTMLElement): void => {
          new BoxAnimation(section);
        });
    }
  }
}
